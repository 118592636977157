import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
  InformationCircleIcon,
  PhoneIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";

import { Layout, WhatsappIcon, Link, SEO } from "../components";

import { phone, support, jobs, whatsApp, email } from "../config";

const options = [
  {
    name: "WhatsApp",
    icon: WhatsappIcon,
    description: (props) => (
      <>
        <div {...props}>Envíanos un mensaje por WhatsApp</div>
      </>
    ),
    action: "Envíanos un mensaje",
    href: whatsApp.href,
  },
  {
    name: "Teléfono",
    icon: PhoneIcon,
    description: (props) => (
      <>
        <div {...props}>
          ¿Prefieres contactanos por teléfono?{" "}
          <span className="block font-medium mt-2 text-blue-500">
            {phone.text}
          </span>
        </div>
      </>
    ),
    action: "Llámanos",
    href: phone.href,
  },
  {
    name: "Centro de Ayuda",
    icon: InformationCircleIcon,
    description: (props) => (
      <>
        <div {...props}>
          Conoce las respuestas a las dudas más frecuentes de nuestra comunidad.
        </div>
      </>
    ),
    action: "Ir al centro de ayuda",
    href: support.href,
  },
];

const ContactUsPage = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        ...SiteInformationFragment
      }
    }
  `);

  const metadata = site.siteMetadata;
  const metaPage = "contacto";
  const metaTitle = "Contáctanos";
  const metaDescription =
    "Ponte en contacto con Bright. Encuentra el WhatsApp, el teléfono, el correo electrónico y la información de contacto de Bright, incluyendo ventas, ayuda, soporte y las consultas generales.";

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebSite",
        "@id": metadata.siteUrl,
        url: metadata.siteUrl,
        name: metadata.title,
        description: metaDescription,
        inLanguage: "es-MX",
      },
      {
        "@type": "ImageObject",
        "@id": `${metadata.siteUrl}${metadata.defaultOgImage}#primaryimage`,
        inLanguage: "es-MX",
        url: `${metadata.siteUrl}${metadata.defaultOgImage}`,
      },
      {
        "@type": "WebPage",
        "@id": `${metadata.siteUrl}/${metaPage}/#webpage`,
        url: `${metadata.siteUrl}/${metaPage}`,
        name: `${metaTitle} · ${metadata.title}`,
        isPartOf: { "@id": metadata.siteUrl },
        primaryImageOfPage: {
          "@id": `${metadata.siteUrl}${metadata.defaultOgImage}`,
        },
        breadcrumb: {
          "@id": `${metadata.siteUrl}/${metaPage}/#breadcrumb`,
        },
        inLanguage: "es-MX",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: [`${metadata.siteUrl}/${metaPage}`],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id": `${metadata.siteUrl}/${metaPage}#breadcrumb`,
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@type": "WebPage",
              "@id": metadata.siteUrl,
              url: metadata.siteUrl,
              name: "Inicio",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@type": "WebPage",
              "@id": `${metadata.siteUrl}/${metaPage}`,
              url: `${metadata.siteUrl}/${metaPage}`,
              name: `${metaTitle} · ${metadata.title}`,
            },
          },
        ],
      },
    ],
  };

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        schemaMarkup={schemaMarkup}
      />
      <div className="bg-white py-24">
        <div className="mx-auto px-8 text-center max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-6xl">
          <h1 className="font-bold text-gray-900 tracking-tight text-4xl md:text-5xl">
            Contacto
          </h1>
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
            Ponte en contacto y cuéntanos cómo podemos ayudarte.
          </p>
          <div className="mt-16">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {options.map((item, index) => (
                <a
                  key={index}
                  href={item.href}
                  target="_blank"
                  className="flex flex-col bg-white rounded-lg shadow-md border border-gray-100 cursor-pointer transform transition duration-200 hover:shadow-lg hover:scale-101 mt-8"
                >
                  <div className="flex-1 p-8 -mt-8">
                    <div className="p-4 inline-block bg-yellow-500 rounded-lg transform -translate-y-1/2">
                      <item.icon className="h-8 w-8 text-white" />
                    </div>
                    <h3 className="text-xl font-medium text-gray-900">
                      {item.name}
                    </h3>
                    <item.description className="mt-5 text-base text-gray-500 px-4 flex-1" />
                  </div>
                  <div className="flex flex-col justify-between bg-gray-50 rounded-lg">
                    <div className="flex items-center justify-center py-3 text-base font-medium text-blue-500">
                      {item.action}
                      <ArrowRightIcon className="h-4 ml-2" />
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 py-16">
        <div className="mx-auto px-8 text-center max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-6xl">
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:divide-x divide-y sm:divide-y-0">
            <div className="px-4 py-5 overflow-hidden sm:p-6">
              <dt className="text-xl font-medium text-gray-900 tracking-tight">
                Comunicación general
              </dt>
              <dd className="mt-4 text-base text-gray-500">
                Para consultas generales envía un correo electrónico a:
                <span className="block mt-2">
                  {/* mailto links should not use Gatsby Links, but simple a-href instead */}
                  <a href={email.href}>{email.text}</a>
                </span>
              </dd>
            </div>
            <div className="px-4 py-5 overflow-hidden sm:p-6">
              <dt className="text-xl font-medium text-gray-900 tracking-tight">
                Únete a nuestro equipo
              </dt>
              <dd className="mt-4 text-base text-gray-500">
                Siempre estamos buscando personas apasionadas y trabajadoras
                <span className="block mt-2">
                  <Link to={jobs.href}>Ver ofertas de trabajo</Link>
                </span>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUsPage;
